import { Col, Divider, Radio, Row, Select } from 'antd';
import {
  FORM_INITIAL_VALUES,
  GetEventData,
  getVenueDropdownData,
  LANDMARKS,
  newAdditionalContact,
  validationSchema
} from './helper';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { GetTabData, PutTabData } from '@services/quoteServices';
import { QuoteBasicDetailsObject, getEventDetails, getTabBaseUrl } from '../helper';
import React, { useEffect, useState } from 'react';
import { getAddressComps, getLocationByPlaceId } from '@utils/googlemaphealper';
import { kitchenAvailabilityList, venuSubSectionList } from './constants';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import AddEditQuoteFooter from '../AddEditQuoteFooter';
import AdditionalContacts from '@pages/VenueManagementPage/AddEditPages/AdditionalContacts';
import Checkbox from '@atom/Checkbox';
import { Checkbox as CheckboxAntd } from 'antd';
import { CheckboxWrapper } from '@pages/QuoteManagementPage/style';
import EventBasicDetails from '../EventBasicDetails';
import { Flex } from '@atom/Flex';
import { GoogleAutoComplete } from '@atom/GoogleAutoComplete';
import { H2Typography } from '@constant/typography/Typography';
import { Input } from '@atom/Input';
import { Label } from '@atom/FormLable';
import RadioButton from '@atom/RadioButton';
import { STATUS } from '@pages/QuoteManagementPage/constants';
import SelectInput from '@atom/Select';
import Switch from '@atom/Switch';
import { TextAreaInput } from '@atom/TextArea';
import TimePickerComponent from '@atom/TimePicker';
import moment, { Moment } from 'moment';
import { showErrorToast, showToast, updateRawData } from '@utils/index';
import { useSelector } from 'react-redux';
import ScrollToFieldError from '@utils/formikFocus';
import { CREATE_EVENT_TAB_KEYS } from '@pages/EventManagementPage/AddEditEvent/helper';
import { ServiceFeeWrapper } from '../FeesTab/style';
import { QUOTE_MANAGEMENT_TABS } from '@pages/QuoteManagementPage/QuotesList/helper';

interface VenueFormProps {
  tabKey: string;
  quoteBasicDetails: QuoteBasicDetailsObject;
  setQuoteBasicDetails: React.Dispatch<React.SetStateAction<QuoteBasicDetailsObject>>;
  isEvent?: boolean;
  submitForm?: (tabKey: string, values: any, actions: any, method?: string) => void;
}

const VenueForm = (props: VenueFormProps) => {
  const { tabKey, quoteBasicDetails, setQuoteBasicDetails, isEvent = false, submitForm } = props;
  const params = useParams();
  const { random_unique_invoice_string } = useSelector((state: any) => state.rawData);

  const { quoteId, eventId } = params;
  const details = useSelector((state: any) => state.rawData?.eventDetails);
  const unique_invoice_string = useSelector((state: any) => state.rawData?.unique_invoice_string);

  const location = useLocation();
  const navigate = useNavigate();

  const [formikData, setFormikData] = useState(FORM_INITIAL_VALUES);
  const [draftSubmitting, setDraftSubmitting] = useState(false);
  const [accountManagersList, setAccountManagersList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const formik = useFormik({
    initialValues: formikData,
    validationSchema: validationSchema,
    onSubmit: (values: any, actions: any) => {
      submitHandler(values, actions);
    }
  });
  const {
    touched,
    errors,
    values,
    setTouched,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues
  } = formik;

  const fetchDropdownData = async () => {
    setLoading(true);
    const res: any = await getVenueDropdownData();
    setAccountManagersList(res?.[0]?.success ? res?.[0]?.data : []);
    setLoading(false);
  };

  const updateInitialData = async () => {
    setLoading(true);
    try {
      const res = await GetTabData(tabKey, quoteId ?? '', isEvent);
      !unique_invoice_string &&
        updateRawData({ unique_invoice_string: res?.data?.unique_invoice_string ?? '' });
      if (res?.success) {
        setFormikData(res?.data?.venue_details);
        const foodFees = res?.data?.venue_details?.food_fees;
        const alcoholSoftBarFees = res?.data?.venue_details?.alcohol_soft_bar_fees;
        const labourFees = res?.data?.venue_details?.labour_fees;
        const onNetTotal = res?.data?.venue_details?.on_net_total;
        setValues({
          ...values,
          ...res?.data?.venue_details,
          event_dates: { ...res?.data?.event_dates },
          food_fees: !onNetTotal ? foodFees : 0,
          alcohol_soft_bar_fees: !onNetTotal ? alcoholSoftBarFees : 0,
          labour_fees: !onNetTotal ? labourFees : 0
        });
        setQuoteBasicDetails((prev: QuoteBasicDetailsObject) => ({
          ...prev,
          id: res?.data?.id,
          quotation_no: res?.data?.quotation_no,
          status: res?.data?.status,
          unique_invoice_string: random_unique_invoice_string
            ? random_unique_invoice_string
            : res?.data?.unique_invoice_string
        }));
      } else {
        showErrorToast({
          message: 'Something went wrong',
          description: 'Failed to fetch quote data.'
        });
      }
    } catch (error: any) {
      showErrorToast({
        message: 'Something went wrong',
        description: 'Failed to fetch quote data.'
      });
    }
    setLoading(false);
  };

  const submitHandler = async (values: any, actions: any, isDraft?: boolean) => {
    if (!isDraft && submitForm) {
      submitForm(
        tabKey,
        {
          venue_details: {
            ...values
          },
          ...details,
          id: quoteId,
          unique_invoice_string,
          event_id: eventId
        },
        actions,
        'put'
      );
      return;
    }
    const res: any = await PutTabData(tabKey, {
      ...quoteBasicDetails,
      venue_details: {
        ...values
      },
      event_dates: { ...values?.event_dates },
      unique_invoice_string: random_unique_invoice_string
        ? random_unique_invoice_string
        : quoteBasicDetails.unique_invoice_string,
      from_event: 0
    });
    if (res?.success) {
      actions?.setSubmitting(false);
      setQuoteBasicDetails((prev: QuoteBasicDetailsObject) => ({
        ...prev,
        id: res?.data?.id || prev?.id,
        quotation_no: res?.data?.quotation_no || prev?.quotation_no,
        status: res?.data?.status,
        unique_invoice_string: random_unique_invoice_string
          ? random_unique_invoice_string
          : res?.data?.unique_invoice_string || prev?.unique_invoice_string
      }));
      const tabBaseUrl = getTabBaseUrl(
        location?.pathname,
        res?.data?.id || quoteId,
        quoteBasicDetails,
        res?.data?.id
      );
      navigate(isDraft ? `/quote/tab/${QUOTE_MANAGEMENT_TABS.DRAFTS}` : `${tabBaseUrl}service`, {
        state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
      });
      if (isDraft) {
        showToast({
          message: 'Success!',
          description: 'Quotation saved as Draft'
        });
      }
    } else {
      actions?.setSubmitting(false);
      actions?.setErrors(res?.error);
      showErrorToast({
        message: 'Failed!',
        description: 'Something Went Wrong!'
      });
    }
  };

  const onPlaceSelect = async (placeId: string) => {
    if (placeId) {
      const place: any = await getLocationByPlaceId(placeId);
      if (place) {
        const locObj = getAddressComps(place);
        if (locObj.formattedAddress.length > 0) {
          const coordinates = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
          };
          setFieldValue(
            'venue_address',
            `${locObj?.formattedAddress}${locObj?.neighbourhood && ', ' + locObj?.neighbourhood}${
              locObj?.city && ', ' + locObj?.city
            }${locObj?.state && ', ' + locObj?.state}${
              locObj?.country && ', ' + locObj?.country
            }` || ''
          );
          setFieldValue('latitude', coordinates?.lat || '');
          setFieldValue('longitude', coordinates?.lng || '');
        }
      }
    }
  };

  const backHandler = () => {
    if (isEvent) {
      const tabBaseUrl = `/events/create/${CREATE_EVENT_TAB_KEYS.CUSTOMER}/${quoteId}/${eventId}`;
      navigate(tabBaseUrl, {
        state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
      });
    } else {
      const tabBaseURL = getTabBaseUrl(location.pathname, quoteId, quoteBasicDetails, eventId);
      navigate(`${tabBaseURL}customer`, {
        state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
      });
    }
  };

  const cancelHandler = () => {
    if (isEvent) {
      navigate('/events');
    } else {
      navigate('/quote');
    }
  };
  const saveAsDraftHandler = async () => {
    setDraftSubmitting(true);
    setFieldValue('status', STATUS.DRAFT);
    await submitHandler({ ...values, status: STATUS.DRAFT }, null, true);
    setDraftSubmitting(false);
  };
  const nextHandler = () => {
    setFieldValue('status', STATUS.ACTIVE);
    handleSubmit();
  };

  useEffect(() => {
    fetchDropdownData();
    redirectToExactUrlHandler;
  }, []);

  useEffect(() => {
    if (tabKey === 'venue') {
      updateInitialData();
    }
  }, [tabKey]);

  const redirectToExactUrlHandler = () => {
    if (!quoteId && quoteBasicDetails?.id) {
      const navigateUrl: string = location?.pathname.includes('edit-quote')
        ? `/quote/edit-quote/${quoteBasicDetails.id}/venue`
        : `/quote/add-quote/${quoteBasicDetails?.id}/venue`;
      navigate(navigateUrl, {
        state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
      });
    }
  };

  const handleUpdateLandmarkFees = (e: React.ChangeEvent<any>) => {
    if (e?.target?.value) {
      values?.labour_fees && setFieldValue('labour_fees', e.target.value);
      values?.alcohol_soft_bar_fees && setFieldValue('alcohol_soft_bar_fees', e.target.value);
      values?.food_fees && setFieldValue('food_fees', e.target.value);
    }
    handleChange(e);
  };

  const removeAdditionalContactMethod = async (item: any, index: number, arrayHelper: any) => {
    if (item?.id) {
      showToast({ message: 'Contact Removed Successfully', description: '' });
    }
    arrayHelper.remove(index);
  };

  return (
    <>
      <ScrollToFieldError formik={formik} />
      <EventBasicDetails />
      <ScrollToFieldError formik={formik} />
      <div id="map" />
      <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col span={24}>
          <H2Typography>Venue</H2Typography>
          <div
            style={{
              marginTop: '18px',
              background: '#E0E0E0',
              marginBottom: '8px',
              height: '1px'
            }}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col md={12} xs={24}>
          <Label text="Venue Name" isMandatory={true} />
          <Input
            id={'venue_name'}
            name={'venue_name'}
            placeholder="Type Name"
            error={touched?.['venue_name'] && errors?.['venue_name']}
            value={values['venue_name']}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col md={12} xs={24}>
          <Label text="Venue Subsection" isMandatory={false} />
          <SelectInput
            id="venue_sub_section"
            name="venue_sub_section"
            allowClear={true}
            placeholdertitle="Select from the list"
            onChange={(value: any) => {
              setFieldValue('venue_sub_section', value);
            }}
            error={touched?.venue_sub_section && errors?.venue_sub_section}
            value={values.venue_sub_section}>
            {venuSubSectionList?.map((data: any) => (
              <Select.Option key={data?.id} value={data?.id}>
                {data?.name}
              </Select.Option>
            ))}
          </SelectInput>
        </Col>
        <Col md={12} xs={24}>
          <Label text="Venue Address" isMandatory={true} />
          <GoogleAutoComplete
            apiKey={process.env.REACT_APP_GOOGLEAPI_KEY}
            id="venue_address"
            name="venue_address"
            value={values['venue_address']}
            placeholder="Type Address"
            onPlaceSelect={(place: any) => {
              if (place) onPlaceSelect(place.place_id);
            }}
            error={touched?.['venue_address'] && errors?.['venue_address']}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col md={12} xs={24}>
          <Label text="Venue Capacity" isMandatory={false} />
          <Input
            id={'capacity'}
            name={'capacity'}
            placeholder="Type Capacity"
            error={touched?.['capacity'] && errors?.['capacity']}
            value={values['capacity']}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>
        <Col md={12} xs={24}>
          <Label text="FD Account Manager" isMandatory={false} />
          <SelectInput
            id="fd_account_manager"
            name="fd_account_manager"
            allowClear={true}
            placeholdertitle="Select For Account Manager"
            onChange={(value: any) => {
              setFieldValue('fd_account_manager', value);
            }}
            error={touched?.fd_account_manager && errors?.fd_account_manager}
            value={values.fd_account_manager}>
            {accountManagersList?.map((manager: any) => (
              <Select.Option key={manager?.id} value={manager?.id}>
                {manager?.name}
              </Select.Option>
            ))}
          </SelectInput>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Col span={24}>
            <Label text="Venue Availability" isMandatory={false} />
          </Col>
          <Row gutter={16}>
            <Col md={12} xs={12}>
              <TimePickerComponent
                id="start_time"
                name="start_time"
                use12Hours={true}
                format="h:mm A"
                placeholder={'Type Time'}
                value={values?.start_time && moment(values?.start_time, 'hh:mm A').format('HH:mm')}
                onChange={(value: string) => {
                  setTouched({ ...touched, start_time: true });
                  setFieldValue('start_time', value);
                }}
                onSelect={(e: Moment) => {
                  setFieldValue('start_time', e.format('hh:mm A'));
                }}
                error={touched.start_time && errors.start_time}
              />
            </Col>
            <Col md={12} xs={12}>
              <TimePickerComponent
                id="end_time"
                name="end_time"
                use12Hours={true}
                format="h:mm A"
                placeholder={'Type Time'}
                value={values?.end_time && moment(values?.end_time, 'hh:mm A').format('HH:mm')}
                onChange={(value: string) => {
                  setTouched({ ...touched, end_time: true });
                  setFieldValue('end_time', value);
                }}
                onSelect={(e: Moment) => {
                  setFieldValue('end_time', e.format('hh:mm A'));
                }}
                error={touched.end_time && errors.end_time}
              />
            </Col>
          </Row>
        </Col>
        <Col lg={12} md={12} xs={24}>
          <Label text="Staff Entrance And Parking" isMandatory={false} />
          <Input
            name="parking_additional_information"
            id="parking_additional_information"
            placeholder="Additional Info"
            onChange={(e: any) => {
              setFieldValue('parking_additional_information', e.target.value);
            }}
            value={values?.parking_additional_information}
            error={
              touched?.parking_additional_information && errors?.parking_additional_information
            }
            onBlur={handleBlur}
          />
        </Col>
      </Row>

      <FormikProvider value={formik}>
        <FieldArray
          name={'additional_contacts'}
          render={(arrayHelper: any) => {
            if (!values?.additional_contacts || values?.additional_contacts?.length === 0)
              return null;
            return values?.additional_contacts?.map((item: any, index: any) => {
              return (
                <AdditionalContacts
                  key={`${index}-${item?.id}-contact`}
                  values={values}
                  touched={touched}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  handleChange={handleChange}
                  arrayHelper={arrayHelper}
                  index={index}
                  item={item}
                  showAdd={true}
                  showRemove={true}
                  onAddContact={() => {
                    setFieldValue(
                      'additional_contacts',
                      values?.additional_contacts
                        ? [...values?.additional_contacts, newAdditionalContact]
                        : [newAdditionalContact]
                    );
                  }}
                  onRemove={() => {
                    removeAdditionalContactMethod(item, index, arrayHelper);
                  }}
                />
              );
            });
          }}
        />
      </FormikProvider>
      <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col md={24} xs={24}>
          <H2Typography>Kitchen Details</H2Typography>

          <div
            style={{
              marginTop: '18px',
              background: '#E0E0E0',
              marginBottom: '8px',
              height: '1px'
            }}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={12} xs={24} style={{ marginBottom: '15px' }}>
          <Label text="Power" isMandatory={false} />
          <RadioButton
            value={values.power_available && Number(values.power_available)}
            onChange={(e) => {
              setFieldValue('power_available', e.target.value);
            }}>
            <Radio value={1}>Yes</Radio>
            <Radio value={0}>No</Radio>
          </RadioButton>
        </Col>
        <Col md={12} xs={24} style={{ marginBottom: '15px' }}>
          <Label text="Running Water" isMandatory={false} />
          <RadioButton
            value={values.running_water && Number(values.running_water)}
            onChange={(e) => {
              setFieldValue('running_water', e.target.value);
            }}>
            <Radio value={1}>Yes</Radio>
            <Radio value={0}>No</Radio>
          </RadioButton>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={12} xs={24}>
          <Label text="Kitchen Availability" isMandatory={false} />
          <SelectInput
            id="kitchen_availability"
            name="kitchen_availability"
            allowClear={true}
            placeholdertitle="Select from the list"
            onChange={(value: any) => {
              setFieldValue('kitchen_availability', value);
            }}
            error={touched?.kitchen_availability && errors?.kitchen_availability}
            value={values.kitchen_availability}>
            {kitchenAvailabilityList?.map((data: any) => (
              <Select.Option key={data?.id} value={data?.id}>
                {data?.name}
              </Select.Option>
            ))}
          </SelectInput>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Label text="In House Cooking Equipment" />
          <TextAreaInput
            name="cooking_equipments"
            id="cooking_equipments"
            placeholder="Type"
            onChange={(e: any) => {
              setFieldValue('cooking_equipments', e.target.value);
            }}
            value={values.cooking_equipments}
            error={errors['cooking_equipments']}
            maxLength={1200}
          />
        </Col>
        <Col md={24} xs={24}>
          <Label text="Special Rental Equipment" />
          <TextAreaInput
            name="special_equipments"
            id="special_equipments"
            placeholder="Type"
            onChange={(e: any) => {
              setFieldValue('special_equipments', e.target.value);
            }}
            value={values.special_equipments}
            error={errors['special_equipments']}
            maxLength={1200}
          />
        </Col>
        <Col md={24} xs={24}>
          <Label text="Catering Rentals Required" />
          <TextAreaInput
            name="rental_equipments"
            id="rental_equipments"
            placeholder="Type"
            onChange={(e: any) => {
              setFieldValue('rental_equipments', e.target.value);
            }}
            value={values.rental_equipments}
            error={errors['rental_equipments']}
            maxLength={1200}
          />
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col span={24}>
          <H2Typography>Alcohol & Bar Set Up</H2Typography>
          <div
            style={{
              marginTop: '18px',
              background: '#E0E0E0',
              marginBottom: '8px',
              height: '1px'
            }}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} md={12} style={{ marginBottom: '15px' }}>
          <Label text="Venue in Charge" isMandatory={false} />
          <RadioButton
            value={values.alcohol_bar_venue_in_charge && Number(values.alcohol_bar_venue_in_charge)}
            onChange={(e) => {
              setFieldValue('alcohol_bar_venue_in_charge', e.target.value);
            }}>
            <Radio value={1}>Yes</Radio>
            <Radio value={0}>No</Radio>
          </RadioButton>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Label text="Bar Service and Requirements" />
          <TextAreaInput
            name="alcohol_bar_setup_requirements"
            id="alcohol_bar_setup_requirements"
            placeholder="Requirements"
            onChange={(e: any) => {
              setFieldValue('alcohol_bar_setup_requirements', e.target.value);
            }}
            value={values.alcohol_bar_setup_requirements}
            error={errors['alcohol_bar_setup_requirements']}
            maxLength={1200}
          />
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col span={24}>
          <H2Typography>Set Up Duties</H2Typography>
          <div
            style={{
              marginTop: '18px',
              background: '#E0E0E0',
              marginBottom: '8px',
              height: '1px'
            }}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <Label text="Set Up Requirements" />
          <TextAreaInput
            name="setup_duty_requirements"
            id="setup_duty_requirements"
            placeholder="Requirements"
            onChange={(e: any) => {
              setFieldValue('setup_duty_requirements', e.target.value);
            }}
            value={values.setup_duty_requirements}
            error={errors['setup_duty_requirements']}
            maxLength={1200}
          />
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col span={24}>
          <H2Typography>Closing Duties</H2Typography>
          <div
            style={{
              marginTop: '18px',
              background: '#E0E0E0',
              marginBottom: '8px',
              height: '1px'
            }}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <Label text="Closing Requirements" />
          <TextAreaInput
            name="closing_duty_requirements"
            id="closing_duty_requirements"
            placeholder="Requirements"
            onChange={(e: any) => {
              setFieldValue('closing_duty_requirements', e.target.value);
            }}
            value={values.closing_duty_requirements}
            error={errors['closing_duty_requirements']}
            maxLength={1200}
          />
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col span={24}>
          <H2Typography>Loading Dock & Parking Info</H2Typography>
          <div
            style={{
              marginTop: '18px',
              background: '#E0E0E0',
              marginBottom: '8px',
              height: '1px'
            }}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} md={12} style={{ marginBottom: '15px' }}>
          <Label text="Loading Dock" isMandatory={false} />
          <RadioButton
            value={values.loading_dock && Number(values.loading_dock)}
            onChange={(e) => {
              setFieldValue('loading_dock', e.target.value);
            }}>
            <Radio value={1}>Yes</Radio>
            <Radio value={0}>No</Radio>
          </RadioButton>
        </Col>
        <Col xs={24} md={12} style={{ marginBottom: '15px' }}>
          <Label text="Parking" isMandatory={false} />
          <RadioButton
            value={values.parking && Number(values.parking)}
            onChange={(e) => {
              setFieldValue('parking', e.target.value);
            }}>
            <Radio value={1}>Yes</Radio>
            <Radio value={0}>No</Radio>
          </RadioButton>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Label text="Loading Dock Addition Info" isMandatory={false} />
          <TextAreaInput
            name="loading_parking_additional_information"
            id="loading_parking_additional_information"
            placeholder="Additional Info"
            onChange={(e: any) => {
              setFieldValue('loading_parking_additional_information', e.target.value);
            }}
            value={values.loading_parking_additional_information}
            error={errors['loading_parking_additional_information']}
            showCount
            maxLength={2000}
          />
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col span={24}>
          <H2Typography>Service Information</H2Typography>
          <div
            style={{
              marginTop: '18px',
              background: '#E0E0E0',
              marginBottom: '8px',
              height: '1px'
            }}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} md={12} style={{ marginBottom: '15px' }}>
          <Label text="Coat Check" isMandatory={false} />
          <RadioButton
            value={values.coat_check && Number(values.coat_check)}
            onChange={(e) => {
              setFieldValue('coat_check', e.target.value);
            }}>
            <Radio value={1}>Yes</Radio>
            <Radio value={0}>No</Radio>
          </RadioButton>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <Label text="Additional Information" />
          <TextAreaInput
            name="coat_check_additional_information"
            id="coat_check_additional_information"
            placeholder="Additional Information"
            onChange={(e: any) => {
              setFieldValue('coat_check_additional_information', e.target.value);
            }}
            value={values.coat_check_additional_information}
            error={errors['coat_check_additional_information']}
            maxLength={1200}
          />
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col span={24}>
          <H2Typography>Staff Details</H2Typography>
          <div
            style={{
              marginTop: '18px',
              background: '#E0E0E0',
              marginBottom: '8px',
              height: '1px'
            }}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <Label text="Additional Restrictions" />
          <TextAreaInput
            name="staff_additional_restriction"
            id="staff_additional_restriction"
            placeholder="Additional Restrictions"
            onChange={(e: any) => {
              setFieldValue('staff_additional_restriction', e.target.value);
            }}
            value={values.staff_additional_restriction}
            error={errors['staff_additional_restriction']}
            maxLength={1200}
          />
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col span={24}>
          <H2Typography>Landmark Fee</H2Typography>
          <div
            style={{
              marginTop: '18px',
              background: '#E0E0E0',
              marginBottom: '8px',
              height: '1px'
            }}
          />
        </Col>
      </Row>

      <Row gutter={16} style={{ width: '100%' }}>
        <ServiceFeeWrapper style={{ display: 'contents' }}>
          {!values.is_customize_landmark_fees ? (
            <>
              <Col md={4} xs={24}>
                <Label text="Landmark Fee" isMandatory={false} />
                <Input
                  id={'landmark_fees'}
                  name={'landmark_fees'}
                  placeholder="%"
                  error={touched?.['landmark_fees'] && errors?.['landmark_fees']}
                  value={values['landmark_fees']}
                  onChange={(e) => handleUpdateLandmarkFees(e)}
                  onBlur={handleBlur}
                />
              </Col>

              <Col className="service-fee-style" md={12} xs={24}>
                <Row gutter={16}>
                  <Col style={{ marginBottom: '10px', fontSize: '16px' }} xs={12}>
                    <Checkbox
                      name="Food"
                      id="food_fees"
                      onChange={(e: React.ChangeEvent<any>) => {
                        setFieldValue(
                          'food_fees',
                          e?.target?.checked ? values?.landmark_fees : null
                        );
                        e?.target?.checked && setFieldValue('on_net_total', 0);
                      }}
                      checked={
                        values?.food_fees == '0.00'
                          ? false
                          : !values?.on_net_total && !!values?.food_fees
                      }
                    />
                  </Col>
                  <Col style={{ marginBottom: '10px', fontSize: '16px' }} md={12} xs={24}>
                    <Checkbox
                      name="Alcohol & Soft Bar"
                      id="alcohol_soft_bar_fees"
                      onChange={(e: React.ChangeEvent<any>) => {
                        setFieldValue(
                          'alcohol_soft_bar_fees',
                          e?.target?.checked ? values?.landmark_fees : null
                        );
                        e?.target?.checked && setFieldValue('on_net_total', 0);
                      }}
                      checked={
                        values?.alcohol_soft_bar_fees == '0.00'
                          ? false
                          : !values?.on_net_total && !!values?.alcohol_soft_bar_fees
                      }
                    />
                  </Col>
                  <Col style={{ marginBottom: '10px', fontSize: '16px' }} xs={12}>
                    <Checkbox
                      name="Labour"
                      id="labour_fees"
                      onChange={(e: React.ChangeEvent<any>) => {
                        setFieldValue(
                          'labour_fees',
                          e?.target?.checked ? values?.landmark_fees : null
                        );
                        e?.target?.checked && setFieldValue('on_net_total', 0);
                      }}
                      checked={
                        values?.labour_fees == '0.00'
                          ? false
                          : !values?.on_net_total && !!values?.labour_fees
                      }
                    />
                  </Col>
                  <Col style={{ marginBottom: '10px', fontSize: '16px' }} md={6} xs={24}>
                    <Checkbox
                      id="on_net_total"
                      name="Net Total"
                      value={LANDMARKS.NET_TOTAL}
                      checked={values?.on_net_total}
                      onChange={(e: any) => {
                        setFieldValue('on_net_total', e.target.checked);
                        if (e.target.checked) {
                          setFieldValue('landmark_fee_checkboxes', []);
                          setFieldValue('food_fees', null);
                          setFieldValue('alcohol_soft_bar_fees', null);
                          setFieldValue('labour_fees', null);
                        }
                      }}
                      style={{ margin: 0 }}
                    />
                  </Col>
                </Row>
              </Col>
            </>
          ) : (
            <>
              <Col md={4} xs={24} lg={4}>
                <Label text="Food" />
                <Input
                  id={'food_fees'}
                  name={'food_fees'}
                  placeholder="%"
                  error={touched?.['food_fees'] && errors?.['food_fees']}
                  value={values['food_fees']}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>
              <Col md={4} xs={24} lg={4}>
                <Label text="Alcohol & Soft Bar" />
                <Input
                  id={'alcohol_soft_bar_fees'}
                  name={'alcohol_soft_bar_fees'}
                  placeholder="%"
                  error={
                    touched &&
                    touched['alcohol_soft_bar_fees'] &&
                    errors &&
                    errors['alcohol_soft_bar_fees']
                  }
                  value={values['alcohol_soft_bar_fees']}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>
              <Col md={4} xs={24} lg={4}>
                <Label text="Labour" />
                <Input
                  id={'labour_fees'}
                  name={'labour_fees'}
                  placeholder="%"
                  error={touched && touched['labour_fees'] && errors && errors['labour_fees']}
                  value={values['labour_fees']}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>
            </>
          )}
          <Col
            style={{ fontWeight: '600' }}
            md={8}
            className="service-fee-style margin-top margin-bottom">
            <Flex gap={12}>
              <Switch
                id="is_customize_landmark_fees"
                checked={values.is_customize_landmark_fees}
                defaultChecked={values.is_customize_landmark_fees}
                onChange={(e: boolean) => {
                  setFieldValue('is_customize_landmark_fees', e);
                  setFieldValue('food_fees', '');
                  setFieldValue('alcohol_soft_bar_fees', '');
                  setFieldValue('labour_fees', '');
                  setFieldValue('on_net_total', false);
                  setFieldValue('landmark_fees', '');
                  if (e) {
                    setFieldValue('landmark_fees', '');
                    setFieldValue('landmark_fee_checkboxes', []);
                    setFieldValue('on_net_total', false);
                  }
                }}
              />
              <span>Customize % Per Category</span>
            </Flex>
          </Col>
        </ServiceFeeWrapper>
      </Row>

      <Row gutter={24} style={{ marginTop: '32px' }}>
        <Col span={24}>
          <H2Typography>Additional Notes</H2Typography>
          <div
            style={{
              marginTop: '18px',
              background: '#E0E0E0',
              marginBottom: '8px',
              height: '1px'
            }}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <Label text="Additional Notes" />
          <TextAreaInput
            name="additional_notes"
            id="additional_notes"
            placeholder="Additional Notes"
            onChange={(e: any) => {
              setFieldValue('additional_notes', e.target.value);
            }}
            value={values.additional_notes}
            error={errors['additional_notes']}
            maxLength={1200}
          />
        </Col>
      </Row>
      <AddEditQuoteFooter
        showBackButton={true}
        isDraftSubmitting={draftSubmitting}
        isSubmitting={formik?.isSubmitting}
        backClickHandler={backHandler}
        cancelClickHandler={cancelHandler}
        saveAsDraftClickHandler={saveAsDraftHandler}
        nextClickHandler={nextHandler}
        isEvent={isEvent}
      />
    </>
  );
};

export default VenueForm;