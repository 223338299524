import { EVENT_WITH_EXISTING_QUOTE_TAB_KEYS } from '@pages/EventManagementPage/EventWithExistingQuote/tabHelpers';
import EventBasicDetails from '@pages/QuoteManagementPage/AddEditQuote/EventBasicDetails';
import { showErrorToast, showToast, updateRawData } from '@utils/index';
import { Spin } from 'antd';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { CREATE_EVENT_TAB_KEYS } from '../../helper';
import {
  getNotes,
  getNotesFormInitialValues,
  notesInitialValues,
  validationSchema
} from './helper';
import ManagerNotes from './ManagerNotes';
import ImportantNotes from './ImportantNotes';
import AddEditQuoteFooter from '@pages/QuoteManagementPage/AddEditQuote/AddEditQuoteFooter';
import {
  getTabBaseUrl,
  QuoteBasicDetailsObject
} from '@pages/QuoteManagementPage/AddEditQuote/helper';
import { STATUS } from '@pages/QuoteManagementPage/constants';
import { PutTabData } from '@services/quoteServices';
import { QUOTE_MANAGEMENT_TABS } from '@pages/QuoteManagementPage/QuotesList/helper';

interface NotesFormProps {
  tabKey: string;
  quoteBasicDetails?: QuoteBasicDetailsObject;
  setQuoteBasicDetails?: React.Dispatch<React.SetStateAction<QuoteBasicDetailsObject>>;
  isEventsTab?: boolean;
  submitForm?: (tabKey: string, values: any, actions: any, method?: string) => void;
  withQuote?: boolean;
}

const Notes = (props: NotesFormProps) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { quoteId, eventId, _formType, _form } = params;
  const { random_unique_invoice_string } = useSelector((state: any) => state.rawData);
  const unique_invoice_string = useSelector((state: any) => state.rawData?.unique_invoice_string);
  const {
    withQuote,
    tabKey,
    quoteBasicDetails = {},
    setQuoteBasicDetails = () => {},
    submitForm,
    isEventsTab = false
  } = props;

  const [formikData, setFormikData] = useState(notesInitialValues);
  const [loading, setLoading] = useState<boolean>(true);
  const [draftSubmitting, setDraftSubmitting] = useState(false);
  const details = useSelector((state: any) => state.rawData?.eventDetails);

  const formik = useFormik({
    initialValues: formikData,
    validationSchema: validationSchema,
    onSubmit: (values: any, actions: any) => {
      submitHandler(values, actions);
    },
    enableReinitialize: true
  });

  const { setValues, handleSubmit, setFieldValue, values } = formik;

  const isInvalidDate = !details?.event_date || details?.event_date === '0000-00-00';

  const submitHandler = async (values: any, actions: any, isDraft?: boolean) => {
    if (!isDraft && submitForm) {
      submitForm(
        tabKey,
        {
          ...values,
          event_id: eventId,
          event_date: details?.event_date
        },
        actions,
        'post'
      );
      formik?.setSubmitting(false);
      return;
    }
    try {
      const res = (await PutTabData(tabKey, {
        ...values,
        ...details,
        ...quoteBasicDetails,
        event_dates: {
          event_date: details?.event_date ?? '',
          start_time: details?.start_time ?? '',
          end_time: details?.end_time ?? ''
        },
        event_id: eventId ?? '',
        event_date: details?.event_date ?? '',
        from_event: 0
      })) as any;
      if (res?.success) {
        actions?.setSubmitting(false);
        setQuoteBasicDetails((prev: QuoteBasicDetailsObject) => ({
          ...prev,
          id: res?.data?.id || prev?.id,
          quotation_no: res?.data?.quotation_no || prev?.quotation_no,
          status: res?.data?.status,
          unique_invoice_string: random_unique_invoice_string
            ? random_unique_invoice_string
            : res?.data?.unique_invoice_string || prev?.unique_invoice_string
        }));

        const tabBaseUrl = getTabBaseUrl(
          location?.pathname,
          res?.data?.id || quoteId,
          quoteBasicDetails,
          res?.data?.id
        );
        navigate(isDraft ? `/quote/tab/${QUOTE_MANAGEMENT_TABS.DRAFTS}` : `${tabBaseUrl}meal-plan`, {
          state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
        });
        if (isDraft) {
          showToast({
            message: 'Success!',
            description: 'Quotation saved as Draft'
          });
        }
      } else {
        actions?.setSubmitting(false);
        showErrorToast({
          message: 'Failed!',
          description: 'Something went wrong'
        });
      }
    } catch (error) {
      actions?.setSubmitting(false);
      showErrorToast({
        message: 'Failed!',
        description: 'Something went wrong'
      });
    }
  };

  const backHandler = () => {
    if (isEventsTab) {
      const tabBaseUrl = withQuote
        ? `/events/find-quote/create/${quoteId}/${eventId}/${EVENT_WITH_EXISTING_QUOTE_TAB_KEYS.VENDORS}`
        : `/events/create/${CREATE_EVENT_TAB_KEYS.SERVICE}/${quoteId}/${eventId}`;
      navigate(tabBaseUrl, {
        state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
      });
    } else {
      const tabBaseURL = getTabBaseUrl(location.pathname, quoteId, quoteBasicDetails, eventId);
      navigate(`${tabBaseURL}service`, {
        state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
      });
    }
  };
  const cancelHandler = () => {
    if (isEventsTab) {
      navigate('/events');
    } else {
      navigate('/quote');
    }
  };

  const nextHandler = () => {
    handleSubmit();
  };

  const setupFormikInitialValues = async () => {
    if (details?.event_date || !isEventsTab) {
      try {
        const res = (await getNotes(
          isEventsTab,
          quoteId ?? '',
          `event_id=${eventId}&event_date=${isInvalidDate ? "" : details?.event_date}`
        )) as any;
        !unique_invoice_string &&
          updateRawData({ unique_invoice_string: res?.data?.unique_invoice_string ?? '' });
        if (res?.success) {
          const eventData = getNotesFormInitialValues(res?.data, isEventsTab);
          setValues(eventData);
          setFormikData(eventData);
          setQuoteBasicDetails((prev: QuoteBasicDetailsObject) => ({
            ...prev,
            id: res?.data?.id,
            quotation_no: res?.data?.quotation_no,
            status: res?.data?.status,
            unique_invoice_string: random_unique_invoice_string
              ? random_unique_invoice_string
              : res?.data?.unique_invoice_string
          }));
        } else {
          showErrorToast({
            message: 'Failed!',
            description: 'Failed to fetch events data.'
          });
        }
      } catch (error: any) {
        showErrorToast({
          message: 'Failed!',
          description: 'Failed to fetch events data.'
        });
      }
    }
    setLoading(false);
  };
  const saveAsDraftHandler = async () => {
    setDraftSubmitting(true);
    setFieldValue('status', STATUS.DRAFT);
    await submitHandler({ ...values, status: STATUS.DRAFT }, null, true);
    setDraftSubmitting(false);
  };

  useEffect(() => {
    if (tabKey === 'notes') {
      setupFormikInitialValues();
    }
  }, [details?.event_id, tabKey]);

  return (
    <>
      <EventBasicDetails />

      {loading ? (
        <Spin />
      ) : (
        <>
          <ImportantNotes formik={formik} />
          <ManagerNotes formik={formik} />
          <AddEditQuoteFooter
            isEvent={isEventsTab}
            showBackButton={true}
            backClickHandler={backHandler}
            cancelClickHandler={cancelHandler}
            saveAsDraftClickHandler={saveAsDraftHandler}
            nextClickHandler={nextHandler}
            isDraftSubmitting={draftSubmitting}
            isSubmitting={formik?.isSubmitting}
          />
        </>
      )}
    </>
  );
};

export default Notes;
