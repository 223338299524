import { commaSeparatedValuesRegex } from '@constants/index';
import services from '@services/index';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  product_subcategory: Yup.object({
    price: Yup.number()
      .typeError('Price Must be number')
      .required('Please enter the price')
      .nullable(),
    description: Yup.string()
      .max(128, 'Description cannot be more than 128 characters.')
      .nullable(),
    image: Yup.string().nullable(),
    ingredients: Yup.array()
      .nullable()
      .of(
        Yup.object({
          ingredient_id: Yup.string().nullable(),
          amount: Yup.string().nullable(),
          measurement_id: Yup.string().nullable(),
          visibility: Yup.string().nullable(),
          prep_list_id: Yup.string().nullable(),
          is_special_ingredient: Yup.string().nullable()
        })
      ),
    sort_order: Yup.number().nullable(),
    copy_paste_ingredient: Yup.string()
      .nullable()
      .matches(commaSeparatedValuesRegex, 'Input must be a valid comma-separated list')
  }),
  product_id: Yup.string().nullable(),
  product_type_id: Yup.string().nullable(),
  product_name: Yup.string().nullable(),
  master_category: Yup.string().nullable()
});

export interface IIngredientType {
  id: number | string;
  ingredient_id?: number | string;
  ingredient_name?: string;
  amount: number | string;
  measurement_id: number | string;
  prep_list_id: number | string;
  visibility: number | string;
  is_special_ingredient: boolean;
}

export interface SpecialIngredientsListType {
  id: number;
  is_associated: number;
  measurement_id: number;
  name: string;
  text: string;
}
export interface IngredientsListType {
  id: number;
  is_associated: number;
  is_special_ingredient: number;
  measurement_id: number;
  name: string;
  text: string;
}
export interface ISubCategoryType {
  id?: string;
  product_id?: string;
  menus?: number[];
  product_type_id?: string;
  product_name?: string;
  master_category?: number | string;
  is_one_time_product?: boolean;
  product_subcategory: {
    sort_order: number | any;
    id?: string;
    subcategory_id?: string;
    price?: string;
    description?: string;
    image?: string;
    is_primary?: boolean;
    ingredients?: IIngredientType[];
    copy_paste_ingredient?: string;
  };
}

export const InitialValues = () => {
  const item: ISubCategoryType = {
    product_subcategory: {
      ingredients: [],
      subcategory_id: undefined,
      price: undefined,
      description: undefined,
      image: undefined,
      is_primary: undefined,
      sort_order: null,
      copy_paste_ingredient: ''
    },
    product_id: '',
    menus: [],
    product_name: '',
    product_type_id: '',
    master_category: ''
  };
  return { ...item };
};

export const getMappedValues = (
  newValues: any,
  oldValues: any,
  parentProductValues: any,
  subCategoryId: number,
  forQuotePopup: boolean
) => {
  const newImage = newValues?.subcategory_image ?? newValues?.image;
  let isMatchingSubcategoryId: boolean = false;
  if (
    newValues?.subcategory_id === oldValues?.product_subcategory?.subcategory_id ||
    subCategoryId === oldValues?.product_subcategory?.subcategory_id
  ) {
    isMatchingSubcategoryId = true;
  }
  const item: ISubCategoryType = {
    product_subcategory: {
      id: !forQuotePopup ? newValues?.id ?? oldValues?.product_subcategory?.id : '',
      subcategory_id: newValues?.subcategory_id || subCategoryId,
      price: newValues?.price
        ? newValues?.price
        : isMatchingSubcategoryId
        ? oldValues?.product_subcategory?.price
        : '',
      description: newValues?.description
        ? newValues?.description
        : isMatchingSubcategoryId
        ? oldValues?.product_subcategory?.description
        : '',
      image: newImage
        ? newImage
        : isMatchingSubcategoryId
        ? oldValues?.product_subcategory?.image
        : null,
      is_primary: newValues?.is_primary
        ? newValues?.is_primary
        : isMatchingSubcategoryId
        ? oldValues?.product_subcategory?.is_primary
        : false,
      sort_order: newValues?.sort_order
        ? newValues?.sort_order
        : isMatchingSubcategoryId
        ? oldValues?.product_subcategory?.sort_order
        : '',
      ingredients: newValues?.ingredients
        ? newValues?.ingredients?.map((item: any) => ({
            ...item,
            id: !forQuotePopup ? item?.id : ''
          }))
        : isMatchingSubcategoryId
        ? oldValues?.product_subcategory?.ingredients?.map((item: any) => ({
            ...item,
            id: !forQuotePopup ? item?.id : ''
          }))
        : []
    },
    product_id: parentProductValues?.product_id ?? '',
    menus: parentProductValues?.menus ?? '',
    product_name: parentProductValues?.product_name ?? '',
    product_type_id: parentProductValues?.product_type_id ?? '',
    master_category: parentProductValues?.master_category ?? '',
    is_one_time_product: parentProductValues?.is_one_time_product ?? undefined
  };
  return { ...item };
};

export const getMeasurements = async () => {
  try {
    const res = (await services.get(`/settings/menu-management/measurements`)) as any;
    return res?.data?.data || {};
  } catch (error) {
    return {};
  }
};

export const getPrepList = async () => {
  try {
    const res = (await services.get(`/settings/menu-management/prep-menu`)) as any;
    return res?.data?.data || {};
  } catch (error) {
    return {};
  }
};

export const getIngredients = async (isSpecialIngredient: boolean) => {
  try {
    const url = isSpecialIngredient
      ? `/settings/menu-management/special-ingredients`
      : `/settings/menu-management/ingredients`;
    const res = (await services.get(url)) as any;
    return res?.data?.data || {};
  } catch (error) {
    return {};
  }
};

export const getSpecialIngredientsItem = async (ingredientId?: number | string) => {
  try {
    const res = (await services.get(
      `/settings/menu-management/special-ingredients/${ingredientId}`
    )) as any;
    return res?.data?.data || {};
  } catch (error) {
    return {};
  }
};

export const getIngredientsData = async (
  productId: number | string,
  subCategoryId: number | string
) => {
  try {
    const res = (await services.get(
      `/product/category-ingredient/${productId}/${subCategoryId}`
    )) as any;
    return res?.data || {};
  } catch (error) {
    return {};
  }
};

export const saveIngredientsData = async (values: any) => {
  try {
    // const res = (await services.get(`/product/edit-detail/${subCategoryId}`)) as any;
    const res = (await services.post(`/product/category-ingredient`, values)) as any;
    return { data: res?.data?.data || {}, success: true };
  } catch (error) {
    return { data: null, success: false, error: error };
  }
};
