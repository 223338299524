import { Label } from '@atom/FormLable';
import SelectInput from '@atom/Select';
import { EVENT_WITH_EXISTING_QUOTE_TAB_KEYS } from '@pages/EventManagementPage/EventWithExistingQuote/tabHelpers';
import AddEditQuoteFooter from '@pages/QuoteManagementPage/AddEditQuote/AddEditQuoteFooter';
import EventBasicDetails from '@pages/QuoteManagementPage/AddEditQuote/EventBasicDetails';
import {
  CREATE_QUOTE_TAB_KEYS,
  getTabBaseUrl,
  QuoteBasicDetailsObject
} from '@pages/QuoteManagementPage/AddEditQuote/helper';
import { STATUS } from '@pages/QuoteManagementPage/constants';
import { QUOTE_MANAGEMENT_TABS } from '@pages/QuoteManagementPage/QuotesList/helper';
import { PutTabData } from '@services/quoteServices';
import { getSelectOptions } from '@utils/helper';
import { showErrorToast, showToast, updateRawData } from '@utils/index';
import { Col, Row, Spin } from 'antd';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { CREATE_EVENT_TAB_KEYS } from '../../helper';
import GuestAllergies from './GuestAllergies';
import {
  getMappedEventData,
  getService,
  getServiceFormInitialValues,
  validationSchema
} from './helper';
import EventTimeline from './Timeline/EventTimeline';

interface ServiceFormProps {
  withQuote?: boolean;
  tabKey: string;
  quoteBasicDetails?: QuoteBasicDetailsObject;
  setQuoteBasicDetails?: React.Dispatch<React.SetStateAction<QuoteBasicDetailsObject>>;
  submitForm?: (tabKey: string, values: any, actions: any, method?: string) => void;
  isEventsTab?: boolean;
}

const Service = (props: ServiceFormProps) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { quoteId, eventId, _formType, _form } = params;
  const { random_unique_invoice_string } = useSelector((state: any) => state.rawData);
  const unique_invoice_string = useSelector((state: any) => state.rawData?.unique_invoice_string);
  const {
    withQuote,
    tabKey,
    quoteBasicDetails = {},
    setQuoteBasicDetails = () => {},
    submitForm,
    isEventsTab = false
  } = props;
  const [formikData, setFormikData] = useState({});
  const [loading, setLoading] = useState<boolean>(true);
  const [draftSubmitting, setDraftSubmitting] = useState(false);

  const details = useSelector((state: any) => state.rawData?.eventDetails);
  const formik = useFormik({
    initialValues: formikData,
    validationSchema: validationSchema,
    onSubmit: (values: any, actions: any) => {
      submitHandler(values, actions);
    }
  });

  const {
    values,
    setValues,
    setFieldValue,
    handleBlur,
    handleSubmit
  } = formik;

  const isInvalidDate = !details?.event_date || details?.event_date === '0000-00-00';

  const submitHandler = async (values: any, actions: any, isDraft?: boolean) => {
    const updatedEventValues = {
      ...values,
      event_time_line: values?.event_time_line?.map((value: any, index: number) => ({
        ...value,
        sort_order: index + 1
      }))
    };
    const updatedValues = {
      ...updatedEventValues,
      ...details,
      ...quoteBasicDetails,
      event_dates: {
        event_date: details?.event_date ?? '',
        start_time: details?.start_time ?? '',
        end_time: details?.end_time ?? ''
      },
      event_id: eventId ?? '',
      event_date: details?.event_date ?? '',
      from_event: 0
    };
    if (!isDraft && submitForm) {
      submitForm(
        tabKey,
        { ...getMappedEventData(updatedEventValues, Number(eventId), details?.event_date) },
        actions,
        'post'
      );
      formik?.setSubmitting(false);
      return;
    }
    try {
      formik?.setSubmitting(true);
      const res = (await PutTabData(tabKey, {
        ...getMappedEventData(updatedValues, Number(eventId), details?.event_date)
      })) as any;
      if (res?.success) {
        actions?.setSubmitting(false);
        setQuoteBasicDetails((prev: QuoteBasicDetailsObject) => ({
          ...prev,
          id: res?.data?.id || prev?.id,
          quotation_no: res?.data?.quotation_no || prev?.quotation_no,
          status: res?.data?.status,
          unique_invoice_string: random_unique_invoice_string
            ? random_unique_invoice_string
            : res?.data?.unique_invoice_string || prev?.unique_invoice_string
        }));
        const tabBaseUrl = getTabBaseUrl(
          location?.pathname,
          res?.data?.id || quoteId,
          quoteBasicDetails,
          res?.data?.id
        );
        navigate(isDraft ? `/quote/tab/${QUOTE_MANAGEMENT_TABS.DRAFTS}` : `${tabBaseUrl}notes`, {
          state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
        });
        if (isDraft) {
          showToast({
            message: 'Success!',
            description: 'Quotation saved as Draft'
          });
        }
      } else {
        actions?.setSubmitting(false);
        showErrorToast({
          message: 'Failed!',
          description: 'Something went wrong'
        });
      }
      formik?.setSubmitting(false);
    } catch (error) {
      actions?.setSubmitting(false);
      showErrorToast({
        message: 'Failed!',
        description: 'Something went wrong'
      });
    }
  };

  const backHandler = () => {
    if (isEventsTab) {
      const tabBaseUrl = withQuote
        ? `/events/find-quote/create/${quoteId}/${eventId}/${EVENT_WITH_EXISTING_QUOTE_TAB_KEYS.STAFFING}`
        : `/events/create/${CREATE_EVENT_TAB_KEYS.STAFFING}/${quoteId}/${eventId}`;
      navigate(tabBaseUrl, {
        state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
      });
    } else {
      const tabBaseURL = getTabBaseUrl(location.pathname, quoteId, quoteBasicDetails, eventId);
      navigate(`${tabBaseURL}venue`, {
        state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
      });
    }
  };
  const cancelHandler = () => {
    if (isEventsTab) {
      navigate('/events');
    } else {
      navigate('/quote');
    }
  };

  const nextHandler = () => {
    handleSubmit();
  };

  const setupFormikInitialValues = async () => {
    if (details?.event_date || !isEventsTab) {
      try {
        const res = (await getService(
          isEventsTab,
          quoteId ?? '',
          `event_id=${eventId}&quote_id=${quoteId}&event_date=${
            isInvalidDate ? '' : details?.event_date
          }`
        )) as any;
        !unique_invoice_string &&
          updateRawData({ unique_invoice_string: res?.data?.unique_invoice_string ?? '' });
        if (res?.success) {
          const eventData = getServiceFormInitialValues(res?.data);
          setValues(eventData);
          setFormikData(eventData);
          setQuoteBasicDetails((prev: QuoteBasicDetailsObject) => ({
            ...prev,
            id: res?.data?.id,
            quotation_no: res?.data?.quotation_no,
            status: res?.data?.status,
            unique_invoice_string: random_unique_invoice_string
              ? random_unique_invoice_string
              : res?.data?.unique_invoice_string
          }));
        } else {
          showErrorToast({
            message: 'Failed!',
            description: 'Failed to fetch events data.'
          });
        }
      } catch (error: any) {
        showErrorToast({
          message: 'Failed!',
          description: 'Failed to fetch events data.'
        });
      }
    } else {
      setValues(getServiceFormInitialValues());
    }
    setLoading(false);
  };

  const saveAsDraftHandler = async () => {
    setDraftSubmitting(true);
    setFieldValue('status', STATUS.DRAFT);
    await submitHandler({ ...values, status: STATUS.DRAFT }, null, true);
    setDraftSubmitting(false);
  };

  useEffect(() => {
    if (tabKey === CREATE_QUOTE_TAB_KEYS.SERVICE) {
      setupFormikInitialValues();
    }
  }, [details?.event_id, tabKey]);

  return (
    <>
      {loading ? (
        <Spin />
      ) : (
        <>
          <EventBasicDetails eventDate="-" eventName="-" venueName="-" customerName="-" />
          <Row gutter={16} style={{ marginTop: '32px' }}>
            <Col md={12} xs={24}>
              <Label text="Date" isMandatory={false} />
              <SelectInput
                id={`event_date`}
                name={`event_date`}
                placeholdertitle="Date"
                value={0}
                error={''}
                onChange={() => {}}
                onBlur={handleBlur}
                allowClear={false}
              >
                {getSelectOptions(
                  [
                    {
                      id: 0,
                      name: details?.event_date
                        ? moment(details?.event_date).format('ddd, MMMM DD')
                        : 'Unassigned Date'
                    }
                  ],
                  'name'
                )}
              </SelectInput>
            </Col>
          </Row>
          <EventTimeline formik={formik} />
          <div style={{ marginTop: '18px' }} />
          <GuestAllergies formik={formik} />
          <AddEditQuoteFooter
            isEvent={isEventsTab}
            showBackButton={true}
            backClickHandler={backHandler}
            cancelClickHandler={cancelHandler}
            saveAsDraftClickHandler={saveAsDraftHandler}
            nextClickHandler={nextHandler}
            isDraftSubmitting={draftSubmitting}
            isSubmitting={formik?.isSubmitting}
          />
        </>
      )}
    </>
  );
};

export default Service;
