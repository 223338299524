import VendorsForm from '@pages/QuoteManagementPage/AddEditQuote/Vendors/VendorsForm';
import Notes from '../AddEditEvent/SharedTabs/Notes';
import Service from '../AddEditEvent/SharedTabs/Service';
import StaffingInformation from '../AddEditEvent/SharedTabs/Staffing';
import DocuementsTab from './EventWithExistingQuoteTabPanels/DocuementsTab';
import QuoteTab from './EventWithExistingQuoteTabPanels/QuoteTab';
import ServiceTab from './EventWithExistingQuoteTabPanels/ServiceTab';
import TasksTab from './EventWithExistingQuoteTabPanels/TasksTab';

export const EVENT_WITH_EXISTING_QUOTE_TAB_KEYS = {
  QUOTE: 'quote',
  STAFFING: 'staffing',
  SERVICE: 'service',
  VENDORS: 'vendors',
  NOTES: 'notes',
  TASKS: 'tasks',
  DOCUMENTS: 'documents'
};
export const getEventWithExistingQuoteTabs = (activePanel: string, eventId: any, other: any) => {
  const {
    onStaffCreated,
    onVendorSubmit,
    onServiceSubmit,
    onTasksSubmit,
    onDocumentSubmit,
    onNotesSubmit
  } = other;
  return [
    {
      tab: 'QUOTE',
      content: <QuoteTab />,
      key: EVENT_WITH_EXISTING_QUOTE_TAB_KEYS.QUOTE
    },
    {
      tab: 'STAFFING',
      content: (
        <StaffingInformation
          withQuote={true}
          onStaffCreated={(quoteId: string, staffValues: any) => {
            onStaffCreated(quoteId, staffValues);
          }}
        />
      ),
      key: EVENT_WITH_EXISTING_QUOTE_TAB_KEYS.STAFFING
    },
    {
      tab: 'SERVICE',
      content: (
        <Service
          tabKey={EVENT_WITH_EXISTING_QUOTE_TAB_KEYS.SERVICE}
          isEventsTab={true}
          withQuote={true}
          submitForm={onServiceSubmit}
        />
      ),
      key: EVENT_WITH_EXISTING_QUOTE_TAB_KEYS.SERVICE,
      disabled: !eventId
    },
    {
      tab: 'VENDORS',
      content: (
        <VendorsForm
          tabKey="vendor"
          withQuote={true}
          isEventsTab={true}
          submitForm={onVendorSubmit}
        />
      ),
      key: EVENT_WITH_EXISTING_QUOTE_TAB_KEYS.VENDORS,
      disabled: !eventId
    },
    {
      tab: 'NOTES',
      content: (
        <Notes
          tabKey={EVENT_WITH_EXISTING_QUOTE_TAB_KEYS.NOTES}
          isEventsTab={true}
          withQuote={true}
          submitForm={onNotesSubmit}
        />
      ),
      key: EVENT_WITH_EXISTING_QUOTE_TAB_KEYS.NOTES,
      disabled: !eventId
    },
    {
      tab: 'TASKS',
      content: <TasksTab withQuote={true} onTasksSubmit={onTasksSubmit} />,
      key: EVENT_WITH_EXISTING_QUOTE_TAB_KEYS.TASKS,
      disabled: !eventId
    },
    {
      tab: 'DOCUMENTS',
      content: <DocuementsTab withQuote={true} onDocumentSubmit={onDocumentSubmit} />,
      key: EVENT_WITH_EXISTING_QUOTE_TAB_KEYS.DOCUMENTS,
      disabled: !eventId
    }
  ];
};
