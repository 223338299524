import PageLayout from '@layout/PageLayout';
import { Flex } from '@atom/Flex';
import { PageTitle } from '@molecules/PageTitle';
import { Button } from '@atom/Buttons';
import VersionHistory from './VersionHistory';

import { EditPencilIcon } from '@assets/index';
import CustomerInformation from './CustomerInformation';
import VenueInformation from './VenueInformation';
import EventDetails from './EventDetails';
import MealPlanAndEstimate from './MealPlanAndEstimate';
import LabourEstimate from './LabourEstimate';
import OthersEstimate from './OthersEstimate';
import { useEffect, useState } from 'react';
import { showErrorToast } from '@utils/index';
import {
  actionDetails,
  ACTION_TYPES,
  getBreadCrumbsRouteViewEventpath,
  getQuote,
  updateQuoteStatus
} from './helper';
import CenterSpiner from '@atom/CenterSpiner';
import { useNavigate, useParams } from 'react-router';
import QuoteStatus from './QuoteStatus';
import Total from './Total';
import { useSelector } from 'react-redux';
import ConfirmModal from '@organism/ConfimationModal';
import { QUOTE_STATUS } from '../constants';
import VendorEstimates from './VendorEstimates';
import { ViewQuoteDetailsWrapper } from './style';
import BreadCrumbs from '@atom/BreadCrumb';
import { localStorageUtil } from '@utils/localstorageUtil';
import { updateRawData } from '@utils/index';
import GuestAllergies from '@pages/EventManagementPage/EventDetails/EventInfo/GuestAllergies';
import EventTimeline from '@pages/EventManagementPage/EventDetails/EventInfo/EventTimeline';

function ViewQuoteDetails() {
  const { quoteId } = useParams();
  const navigate = useNavigate();
  const location = localStorageUtil.getValue('location');
  const [quoteData, setQuoteData] = useState<any>({});
  const [isLoadingQuote, setIsLoadingQuote] = useState<boolean>(true);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [actionType, setActionType] = useState<number>(-1);
  const user = useSelector((state: { user: any }) => state.user);
  const { isAdmin } = user;

  useEffect(() => {
    updateQuoteData(quoteId);
  }, [quoteId]);

  const updateQuoteData = async (quoteId: any) => {
    setIsLoadingQuote(true);
    try {
      const res = await getQuote(quoteId);
      if (res?.success) {
        setQuoteData(res?.data);
      } else {
        setIsLoadingQuote(false);
        showErrorToast({
          message: 'Failed!',
          description: 'Failed to fetch quote data'
        });
      }
    } catch (error) {
      setIsLoadingQuote(false);
      showErrorToast({
        message: 'Failed!',
        description: 'Failed to fetch quote data'
      });
    }
    setIsLoadingQuote(false);
  };

  const generateUUID = () => {
    // Public Domain/MIT
    let d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      d += performance.now(); //use high-precision timer if available
    }
    let newGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      let r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    updateRawData({ random_unique_invoice_string: newGuid });
    return newGuid;
  };

  const handleConfirm = async () => {
    if (actionType === ACTION_TYPES.EDIT) {
      setActionType(-1);
      setIsModalVisible(false);
      localStorageUtil.setValue('location', {
        state: { prevPath: location?.pathname, path: 'quote' }
      });
      navigate(`/quote/edit-quote/${quoteId}/customer`, {
        state: { prevPath: location?.pathname, path: 'quote' }
      });
    } else if (actionType === ACTION_TYPES.APPROVE) {
      const temp_unique_invoice_string = generateUUID();
      setActionType(-1);
      setIsModalVisible(false);
      const res = await updateQuoteStatus(
        ACTION_TYPES.APPROVE,
        quoteId,
        temp_unique_invoice_string
      );
      if ('data' in res && res.data.data.quotation_id) {
        const updatedQuoteId = res.data.data.quotation_id;
        updateQuoteData(updatedQuoteId);
        // setQuoteData(res?.data?.data);
        navigate(`/quote/view/${updatedQuoteId}`);
      } else {
        updateQuoteData(quoteId);
      }
    } else if (actionType === ACTION_TYPES.REJECT) {
      setActionType(-1);
      setIsModalVisible(false);
      await updateQuoteStatus(ACTION_TYPES.REJECT, quoteId);
      updateQuoteData(quoteId);
    }
  };

  const onVersionUpdate = (quoteId: any) => {
    navigate(`/quote/view/${quoteId}`);
    updateQuoteData(quoteId);
  };

  const handleEdit = () => {
    if (quoteData?.status === QUOTE_STATUS.DRAFT) {
      localStorageUtil.setValue('location', {
        state: { prevPath: location?.pathname, path: 'quote' }
      });
      navigate(`/quote/edit-quote/${quoteId}/customer`, {
        state: { prevPath: location?.pathname, path: 'quote' }
      });
    } else {
      setActionType(ACTION_TYPES.EDIT);
      setIsModalVisible(true);
    }
  };

  if (isLoadingQuote) {
    return <CenterSpiner />;
  }

  return (
    <ViewQuoteDetailsWrapper>
      <PageLayout>
        <BreadCrumbs routes={getBreadCrumbsRouteViewEventpath()} />

        <Flex direction="column" style={{ width: '100%', marginTop: '15px' }}>
          <PageTitle text="QUOTE MANAGEMENT" isBack={true} path={'/quote'} isBottom bordered>
            {isAdmin && !quoteData?.from_event && (
              <Flex className="page-btn">
                <Button
                  variant="ghost"
                  icon={<img src={EditPencilIcon} alt="edit" />}
                  onClick={() => handleEdit()}
                  text="EDIT"
                />
              </Flex>
            )}
          </PageTitle>
        </Flex>
        <QuoteStatus
          data={quoteData}
          setIsModalVisible={setIsModalVisible}
          setActionType={setActionType}
        />
        <VersionHistory data={quoteData} onVersionUpdate={onVersionUpdate} />
        <CustomerInformation data={quoteData} />
        <VenueInformation data={quoteData} />
        <EventDetails data={quoteData} />
        <EventTimeline data={quoteData} />
        <MealPlanAndEstimate data={quoteData} isEventData={false} eventDate={''} />
        <GuestAllergies data={quoteData?.guest_allergies} />
        {quoteData?.event_dates?.labours?.length ? <LabourEstimate data={quoteData} /> : null}
        {quoteData?.event_dates?.vendors?.length ? <VendorEstimates data={quoteData} /> : null}
        {quoteData?.event_dates?.other_details?.length ? <OthersEstimate data={quoteData} /> : null}

        <Total
          data={quoteData}
          hideButtons={!!quoteData?.from_event}
          updateQuoteData={updateQuoteData}
        />
        <ConfirmModal
          visible={isModalVisible}
          onCancel={() => {
            setActionType(-1);
            setIsModalVisible(false);
          }}
          title={actionDetails?.[actionType]?.title}
          onOK={handleConfirm}
          okText={actionDetails?.[actionType]?.okText}
          cancelText={actionDetails?.[actionType]?.cancelText}>
          {actionDetails?.[actionType]?.body}
        </ConfirmModal>
      </PageLayout>
    </ViewQuoteDetailsWrapper>
  );
}

export default ViewQuoteDetails;
